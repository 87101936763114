import moment from 'moment-timezone'
import { useToast } from 'vue-toastification'
import { useApiErrorsStore, fetchToApiError } from '~~/store/api_errors'

export const useBill = () => {
  const { t } = useNuxtApp().$i18n
  const initialBillValues = {
    app_locale: useNuxtApp().$i18n?.locale?.value,
    note: null,
    table_id: null,
    payment_image: null,
    bill_details: null,
    payment_office: null,
    agent_place_id: null,
    store_id: null,
    branch_id: null,
    delivery_method: null,
    payment_type: null,
    payment_details: null
  }
  const loadingBuyBill = ref<boolean>(false)
  let form = reactive<Record<string, any>>(initialBillValues)
  const resetForm = () => {
    form = initialBillValues
  }
  const fetchBill = async (idBill?: number|string) => {
    const dataFetchBill = await useNuxtApiFetch(
      '/user/orders/order',
      {
        query: {
          id: idBill || useRoute().params.id,
          domain: useDomainHeader()
        },

        transform: (data: SuccessResponse<UserOrder>) => {
          return data.data
        }
      }
    )
    return dataFetchBill
  }
  const { isAuthLoggedIn } = useMenuModel()
  const { form: profileForm } = useProfile()
  const { catchError, setErrors, serverLoad } = useCartStore()
  const toast = useToast()
  const buyBill = async () => {
    if (loadingBuyBill.value) {
      return
    }
    setErrors(form.store_id, undefined)
    loadingBuyBill.value = true
    try {
      const formData = new FormData()

      for (const key in form) {
        if (Object.prototype.hasOwnProperty.call(form, key) && form[key] !== undefined && form[key] !== null) {
          formData.append(key, form[key])
        }
      }
      const resultApi = await $fetch('/nuxt-api/user/cart/bill', {
        method: 'POST',
        body: formData
      })
      if (resultApi.data[0].id) {
        loadingBuyBill.value = false

        await serverLoad(true)
        toast.success(t('order_completed'), { timeout: 1000 })
        const localePath = useLocalePath()
        useRouter().replace(
          localePath({
            path: '/successful-purchase-cart',
            query: { bill_id: resultApi.data[0].id }
          })
        )
      }
    } catch (error) {
      catchError(error, form.store_id, t('order_faild'))
      loadingBuyBill.value = false
      return false
    }
  }
  const checkErrorBill = (
    store: Store,
    totalPrice: number,
    table?: Table,
    selectedPayWays?: {
      payType?: String
      payWayCard?: payWay
    },
    selectedShippingWay?: ShippingWay
  ) => {
    const isStoreOpening = chechIfStoreIsOpennning(store)
    const isStoreHasPayWays =
      store?.pay_on_door || (store?.pay_by_transfer && store?.pay_ways?.length)
    if (!isStoreOpening) {
      return
    }
    if (!isAuthLoggedIn()) {
      const localePath = useLocalePath()
      toast.error(t('login_required'), { timeout: 1000 })
      setTimeout(() => {
        useRouter().push(
          localePath({ path: '/auth/login', query: { callbackUrl: '/cart' } })
        )
      }, 1000)
      return true
    }
    if (!profileForm.phone?.trim()) {
      toast.error(t('must_have_a_phone_number'), { timeout: 2300 })
      return true
    }
    if (!table?.table_number) {
      if (
        selectedShippingWay?.title !== t('store_pickup') &&
        !selectedShippingWay?.agentPlace?.id
      ) {
        toast.error(t('not_chosen_any_address'), { timeout: 1000 })
        return true
      }
    }
    if (isStoreHasPayWays && !selectedPayWays?.payType) {
      toast.error(t('choose_one_of_the_payment_methods_first'), {
        timeout: 1000
      })
      return true
    }
    if (
      isStoreHasPayWays &&
          selectedPayWays?.payType === t('pay_by_transfer') &&
          !selectedPayWays?.payWayCard
    ) {
      toast.error(t('error_payWayCard'), { timeout: 1500 })
      return true
    }
    if (!(totalPrice > 0)) {
      toast.error(t('no_products_to_purchase'), { timeout: 1000 })
      return true
    }
    return false
  }
  const chechIfStoreIsOpennning = (store: Store) => {
    const currentTime = moment().utc()
    const openingTime = moment.utc(store.time_open, 'HH:mm:ss')
    const closingTime = moment.utc(store.time_close, 'HH:mm:ss')
    const toast = useToast()
    const isStoreAvailableToBuy =
      currentTime.isBetween(openingTime, closingTime) && store.available_to_buy
    if (isStoreAvailableToBuy) {
      return true
    } else {
      toast.error(
        store.platform_hint || t('message_error_store_not_available_to_buy'),
        { timeout: 4000 }
      )
      return false
    }
  }
  return {
    form,
    buyBill,
    checkErrorBill,
    resetForm,
    fetchBill,
    loadingBuyBill,
    chechIfStoreIsOpennning
  }
}
export const useRateBill = (billId: number) => {
  const toast = useToast()
  const { t } = useI18n()
  const { addApiError, deleteApiError } = useApiErrorsStore()
  const loadingRate = ref<boolean>(false)
  async function rateBill(stars: number, noteUser: string): Promise<boolean> {
    try {
      deleteApiError('rate_bill')
      loadingRate.value = true
      await $fetch('/nuxt-api/user/orders/rate', {
        method: 'POST',
        body: {
          app_locale: useNuxtApp().$i18n?.locale?.value,
          bill_id: billId,
          rate: stars,
          note: noteUser
        }
      })

      toast.success(t('rate_done'), { timeout: 1000 })
      loadingRate.value = false
      return true
    } catch (error) {
      toast.error(t('rate_failed'), { timeout: 1000 })
      loadingRate.value = false
      addApiError(fetchToApiError(error), 'rate_bill')
      return false
    }
  }

  return {
    loadingRate,
    rateBill
  }
}
